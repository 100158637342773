@import "slick-carousel/slick/slick.css";
@import "slick-carousel/slick/slick-theme.css";
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800&display=swap');

* {
  margin: 0;
  padding: 0;
}

a {
  text-decoration: none;
  font-family: 'Inter', sans-serif;
}

h1,
h2,
h3,
h4,
p {
  font-family: 'Inter', sans-serif;
}
html,
body {
  overflow-x: hidden;
  &::-webkit-scrollbar {
    width: 8px; /* ширина для вертикального скролла */
    height: 8px; /* высота для горизонтального скролла */
    background-color: #f4f6f8;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #161c2d;
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: #161c2d;
  }
}

.temir_text {
  animation: card 0.6s ease-in;
}

.form__input {
  animation: forn_card 0.6s ease-in;
}

@keyframes card {
  from {
    opacity: 0;
    transform: translateX(200px);
  }
  55% {
    opacity: 0;
  }
  to {
    transform: translateY(0);
  }
}

@keyframes forn_card {
  from {
    opacity: 0;
    transform: translateX(-200px);
  }
  55% {
    opacity: 0;
  }
  to {
    transform: translateY(0);
  }
}

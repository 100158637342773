$transitionTime: "1" ".5", "2" ".7", "3" "1", "4" "1.2", "5" "1.5";

.burger-button {
  background: transparent;
  position: relative;
  z-index: 10;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  gap: 5px;

  @media screen and (min-width: 992px) {
    display: none;
  }

  &-top,
  &-bottom {
    background: #7e8085;
    transition: all 0.5s ease-in-out;
    margin-bottom: 5px;
  }

  &.active {
    .burger-button-top {
      transform: rotate(45deg) translate(0px, 10px);
      width: 30px;
      z-index: 50;
    }

    .burger-button-bottom {
      transform: rotate(-45deg) translate(-2px, -10px);
      width: 30px;
      z-index: 50;
    }
  }
}

.mobile-menu {
  transform: translateX(150vw);
  transition: all 0.55s;
  opacity: 0;

  &.transform {
    transform: translateX(0);
    opacity: 1;

    @media screen and (min-width: 992px) {
      display: none;
    }
  }

  &-link {
    display: flex;
    justify-content: end;
    font-size: 24px;
    margin-bottom: 46px;
    font-weight: 600;

    &.active {
      border-radius: 10px;
      background: rgba(255, 255, 255, 0.15);
      padding: 20px;
    }
  }
}
